<template>
  <div class="period-index">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-select
            v-model="searchModel.companyGuid"
            placeholder="所属公司"
            clearable>
            <el-option
              v-for="company in companys"
              :key="company.companyGuid"
              :label="company.companyName"
              :value="company.companyGuid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="searchModel.projectGuid"
            placeholder="所属项目"
            clearable>
            <el-option
              v-for="project in projects"
              :key="project.projectGuid"
              :label="project.projectName"
              :value="project.projectGuid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="searchModel.tenderGuid"
            placeholder="所属标段"
            clearable>
            <el-option
              v-for="tender in tenders"
              :key="tender.tenderGuid"
              :label="tender.tenderName"
              :value="tender.tenderGuid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearchClick">查询</el-button>
          <el-button type="primary" @click="handleAddClick">添加</el-button>
          <el-button type="primary" @click="handleBatchAddClick">批量生成</el-button>
          <el-button type="primary" @click="handleAppendClick"
                     :disabled="!searchModel.companyGuid || !searchModel.projectGuid || !searchModel.tenderGuid || !periodList.length">
            追加
          </el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <el-table
      :data="periodList"
      header-cell-class-name="bg-info text-light"
      border
    >
      <el-table-column width="50" header-align="center" align="center" label="序号" type="index"></el-table-column>
      <el-table-column prop="periodName" header-align="center" align="center" label="名称"></el-table-column>
      <el-table-column prop="periodAlias" header-align="center" align="center" label="别名"></el-table-column>
      <el-table-column prop="endDate" width="120" header-align="center" align="center"
                       label="结束日期"></el-table-column>
      <el-table-column prop="tenderName" header-align="center" align="center" label="标段名称"></el-table-column>
      <el-table-column prop="staffName" width="80" header-align="center" align="center"
                       label="创建者"></el-table-column>
      <el-table-column prop="sortId" width="50" header-align="center" align="center" label="排序"></el-table-column>
      <el-table-column prop="createTime" width="160" :formatter="timeFormatter" header-align="center" align="center"
                       label="创建时间"></el-table-column>
      <el-table-column header-align="center" width="90" align="center" label="操作">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="light" content="编辑" :open-delay="500" placement="top">
            <i @click="handleEditClick(scope.row)" class="text-info far fa-edit mr-2 operation-button"></i>
          </el-tooltip>
          <el-tooltip class="item" effect="light" content="删除" :open-delay="500" placement="top">
            <i @click="handleDeleteClick(scope.row)" class="text-danger far fa-trash-alt operation-button"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <my-dialog ref="formDialog"
               :title="formDialogTitle"
               size="md"
               ok-text="保存"
               cancel-text="取消"
               @ok-click="handleOkClick">
      <period-form
        ref="periodForm"
        :periodModel="periodModel">
      </period-form>
    </my-dialog>

    <my-dialog ref="periodFormDialog"
               title="批量生成期数信息"
               size="md"
               ok-text="保存"
               cancel-text="取消"
               @ok-click="handleBatchOkClick">
      <period-batch-form
        ref="periodBatchForm"
        :periodBatchModel="periodBatchModel">
      </period-batch-form>
    </my-dialog>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import MyDialog from '@/components/MyDialog/MyDialog'
import PeriodForm from '@/views/PeriodManage/PeriodForm'
import PeriodBatchForm from '@/views/PeriodManage/PeriodBatchForm'
import PeriodModel from '@/model/PeriodModel'
import periodService from '@/services/periodService'
import PageOperation from '@/mixins/PageOperation'
import GetTender from '@/mixins/GetTender'

export default {
  name: 'PeriodIndex',
  mixins: [
    GetTender,
    PageOperation
  ],
  components: {
    MyDialog,
    MySearchBar,
    PeriodForm,
    PeriodBatchForm
  },
  data () {
    return {
      periodList: [],
      periodModel: new PeriodModel(),
      periodBatchModel: {
        tenderGuid: '',
        startPeriod: 1,
        totalPeriod: 1,
        startPeriodDate: ''
      },
      formDialogTitle: '',
      searchModel: {
        companyGuid: '',
        projectGuid: '',
        tenderGuid: ''
      }
    }
  },
  watch: {
    companyGuid (newVal) {
      this.$set(this.searchModel, 'projectGuid', '')
      this.$set(this.searchModel, 'tenderGuid', '')
      this.projects = []
      this.tenders = []
      newVal && this._getProjects(newVal)
      this.periodList = []
    },
    projectGuid (newVal) {
      this.$set(this.searchModel, 'tenderGuid', '')
      this.tenders = []
      newVal && this._getTenders(newVal)
      this.periodList = []
    },
    tenderGuid (newVal) {
      this.periodList = []
    }
  },
  computed: {
    companyGuid () {
      return this.searchModel.companyGuid
    },
    projectGuid () {
      return this.searchModel.projectGuid
    },
    tenderGuid () {
      return this.searchModel.tenderGuid
    }
  },
  methods: {
    handleSearchClick () {
      this._getPeriodList()
    },
    handleAppendClick () {
      this.formDialogTitle = '添加工期信息'
      this.periodModel = new PeriodModel()
      const periodName = (this.periodList && this.periodList.length) ? parseInt(this.periodList[this.periodList.length - 1].periodName) + 1 : 1
      this.periodModel.periodName = periodName.toString()
      this.periodModel.periodAlias = `第${periodName}期`
      this.periodModel.companyGuid = this.searchModel.companyGuid
      this.periodModel.projectGuid = this.searchModel.projectGuid
      this.periodModel.tenderGuid = this.searchModel.tenderGuid
      this.periodModel.sortId = periodName
      this.$refs.formDialog.open()
      this.$nextTick(() => {
        this.$refs.periodForm.fill(this.searchModel.companyGuid, this.searchModel.projectGuid)
      })
    },
    handleAddClick () {
      this.formDialogTitle = '添加工期信息'
      this.periodModel = new PeriodModel()
      this.$refs.formDialog.open()
    },
    handleBatchAddClick () {
      this.periodBatchModel = {
        tenderGuid: '',
        startPeriod: 1,
        totalPeriod: 1,
        startPeriodDate: ''
      }
      this.$refs.periodFormDialog.open()
    },
    handleEditClick (row) {
      this.formDialogTitle = '编辑工期信息'
      this.periodModel = new PeriodModel(row)
      this.$refs.formDialog.open()
    },
    handleDeleteClick (row) {
      this.$confirm('您确定要删除吗？')
        .then(() => {
          let _periodModel = new PeriodModel(row)
          periodService.delete(_periodModel.resourceId)
            .then(res => {
              window.console.log(res)
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '删除成功！'
                })
                this._getPeriodList()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              window.console.log(err)
              this.$message({
                type: 'error',
                message: '删除失败！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消删除！'
          })
        })
    },
    handleOkClick () {
      this.$refs.periodForm.validate()(valid => {
        if (valid) {
          if (!this.periodModel.resourceId) {
            this._save()
          } else {
            this._edit()
          }
        } else {
          this.$message({
            type: 'error',
            message: '请检查您的输入！'
          })
        }
      })
    },
    handleBatchOkClick () {
      this.$refs.periodBatchForm.validate()(valid => {
        if (valid) {
          periodService.batchAdd(this.periodBatchModel.tenderGuid,
            this.periodBatchModel.startPeriod,
            this.periodBatchModel.totalPeriod,
            this.periodBatchModel.startPeriodDate)
            .then(res => {
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '操作成功！'
                })
                this._getPeriodList()
                this.$refs.periodFormDialog.close()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              console.log(err)
              this.$message({
                type: 'error',
                message: '批量生成失败！'
              })
            })
        } else {
          this.$message({
            type: 'error',
            message: '请检查您的输入！'
          })
        }
      })
    },
    _getPeriodList () {
      periodService.list({}, this.searchModel)
        .then(res => {
          if (res.data.code === 1) {
            this.periodList = res.data.data
            this.$message({
              type: 'success',
              message: '列表加载成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '列表加载失败！'
          })
        })
    },
    _save () {
      periodService.add(new PeriodModel({ ...this.periodModel, periodName: this.periodModel.periodName.toString() }))
        .then(res => {
          console.log(res)
          if (res.data.code === 1) {
            this.$refs.formDialog.close()
            this._getPeriodList()
            this.$message({
              type: 'success',
              message: '添加成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '添加失败！'
          })
          window.console.log(err.request.response)
        })
    },
    _edit () {
      periodService.edit({ ...this.periodModel, periodName: this.periodModel.periodName.toString() })
        .then(res => {
          window.console.log(res)
          if (res.data.code === 1) {
            this.$refs.formDialog.close()
            this._getPeriodList()
            this.$message({
              type: 'success',
              message: '修改成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '修改失败！'
          })
          window.console.log(err.request.response)
        })
    }
  },
  created () {
    this._getPeriodList()
  }
}
</script>
