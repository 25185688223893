import companyService from '@/services/companyService'
import projectService from '@/services/projectService'
import tenderService from '@/services/tenderService'
import PageInfo from '@/support/PageInfo'
import config from '@/config'

export default {
  data () {
    return {
      companys: [],
      projects: [],
      tenders: []
    }
  },
  methods: {
    _getCompanys () {
      companyService.list()
        .then(res => {
          if (res.data.code === 1) {
            this.companys = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: '获取数据失败！'
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          console.log(err.request.response)
        })
    },
    _getTenders (projectGuid) {
      tenderService.list({}, { projectGuid })
        .then(res => {
          if (res.data.code === 1) {
            this.tenders = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          console.log(err.request.response)
        })
    },
    _getProjects (companyGuid) {
      projectService.list(new PageInfo({ pageIndex: 1, pageSize: config.bigPageSize, fieldOrder: 'projects.sortId' }), { companyGuid })
        .then(res => {
          if (res.data.code === 1) {
            this.projects = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          console.log(err.request.response)
        })
    }
  },
  created () {
    this._getCompanys()
  }
}
