import BaseService from '@/services/BaseService'
import axios from 'axios'
import utility from '@/common/utility'
import auth from '@/common/auth'

let resourceName = 'period'
let batchAddUrl = 'BatchAdd'

class PeriodService extends BaseService {
  batchAdd (tenderGuid, startPeriod, totalPeriod, startPeriodDate) {
    return axios.post(utility.getFullUrl(this.resourceName, batchAddUrl), utility.getFormData({
      tenderGuid,
      startPeriod,
      totalPeriod,
      startPeriodDate
    }), {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }
}

const periodService = new PeriodService(resourceName)

export default periodService
