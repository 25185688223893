<template>
  <el-form
    ref="elForm"
    :rules="rules"
    :model="periodModel"
    label-width="100px"
    >
    <el-form-item label="名称：" prop="periodName">
      <el-input-number
        v-model="periodModel.periodName"
        :step="1"
        :min="1"
        :precision="0"
        step-strictly>
        >
      </el-input-number>
    </el-form-item>
    <el-form-item label="别名：" prop="periodAlias">
      <el-input
        type="text"
        v-model="periodModel.periodAlias">
      </el-input>
    </el-form-item>
    <el-form-item label="结束日期：" prop="endDate">
      <el-date-picker
        v-model="periodModel.endDate"
        type="date"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        style="width: 100%;">
      </el-date-picker>
    </el-form-item>
    <template v-if="!periodModel.resourceId">
      <el-form-item label="所属公司：" prop="companyGuid">
        <el-select v-model="companyGuid" clearable>
          <el-option
            v-for="company in companys"
            :key="company.companyGuid"
            :label="company.companyName"
            :value="company.companyGuid"
            >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属项目：" prop="projectGuid">
        <el-select v-model="projectGuid" clearable>
          <el-option
            v-for="project in projects"
            :key="project.projectGuid"
            :label="project.projectName"
            :value="project.projectGuid"
            >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属标段：" prop="tenderGuid">
        <el-select v-model="periodModel.tenderGuid" clearable>
          <el-option
            v-for="tender in tenders"
            :key="tender.tenderGuid"
            :label="tender.tenderName"
            :value="tender.tenderGuid"
            >
          </el-option>
        </el-select>
      </el-form-item>
    </template>
    <template v-else>
      <el-form-item label="所属标段：">
        <el-input
          disabled
          :value="periodModel.tenderName">
        </el-input>
      </el-form-item>
    </template>
    <el-form-item label="排序：" prop="sortId">
      <el-input-number
        v-model="periodModel.sortId"
        :step="1"
        :min="0"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
  </el-form>
</template>

<script>
import PeriodModel from '@/model/PeriodModel'
import GetTender from '@/mixins/GetTender'
import tenderService from '@/services/tenderService'
import projectService from '@/services/projectService'
import PageInfo from '@/support/PageInfo'
import config from '@/config'

export default {
  name: 'PeriodForm',
  mixins: [
    GetTender
  ],
  props: {
    periodModel: {
      type: PeriodModel,
      default () {
        return new PeriodModel()
      }
    }
  },
  watch: {
    companyGuid (newVal) {
      if (!this.filling) {
        this.projectGuid = ''
        this.periodModel.tenderGuid = ''
        this.projects = []
        this.tenders = []
        newVal && this._getProjects(newVal)
      }
    },
    projectGuid (newVal) {
      if (!this.filling) {
        this.periodModel.tenderGuid = ''
        this.tenders = []
        newVal && this._getTenders(newVal)
      }
    }
  },
  data () {
    return {
      filling: false,
      companyGuid: '',
      projectGuid: '',
      rules: {
        periodName: [
          {
            required: true,
            message: '请输入名称',
            trigger: 'blur'
          }
        ],
        periodAlias: [
          {
            required: true,
            message: '请输入别名',
            trigger: 'blur'
          }
        ],
        endDate: [
          {
            required: true,
            message: '请输入结束日期',
            trigger: 'blur'
          }
        ],
        tenderGuid: [
          {
            required: true,
            message: '请选择所属标段',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    validate () {
      return this.$refs.elForm.validate
    },
    async fill (companyGuid, projectGuid) {
      this.filling = true
      this.companyGuid = companyGuid
      this.projectGuid = projectGuid
      await this.getProjects(companyGuid)
      await this.getTenders(projectGuid)
      this.filling = false
    },
    async getTenders (projectGuid) {
      try {
        const response = await tenderService.list({}, { projectGuid })
        if (response.data.code === 1) {
          this.tenders = response.data.data
        } else {
          this.$message.error(response.data.msg)
        }
      } catch (err) {
        this.$message.error('获取数据失败')
      }
    },
    async getProjects (companyGuid) {
      try {
        const response = await projectService.list(new PageInfo({ pageIndex: 1, pageSize: config.bigPageSize, fieldOrder: 'projects.sortId' }), { companyGuid })
        if (response.data.code === 1) {
          this.projects = response.data.data
        } else {
          this.$message.error(response.data.msg)
        }
      } catch (err) {
        this.$message.error('获取数据失败')
      }
    }
  },
  created () {
  }
}
</script>
