<template>
  <el-form
    ref="elForm"
    :rules="rules"
    :model="periodBatchModel"
    label-width="120px"
    >
    <el-form-item label="所属公司：" prop="companyGuid">
      <el-select v-model="companyGuid" clearable>
        <el-option
          v-for="company in companys"
          :key="company.companyGuid"
          :label="company.companyName"
          :value="company.companyGuid"
          >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="所属项目：" prop="projectGuid">
      <el-select v-model="projectGuid" clearable>
        <el-option
          v-for="project in projects"
          :key="project.projectGuid"
          :label="project.projectName"
          :value="project.projectGuid"
          >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="所属标段：" prop="tenderGuid">
      <el-select v-model="periodBatchModel.tenderGuid" clearable>
        <el-option
          v-for="tender in tenders"
          :key="tender.tenderGuid"
          :label="tender.tenderName"
          :value="tender.tenderGuid"
          >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="开始期数：" prop="startPeriod">
      <el-input-number
        v-model="periodBatchModel.startPeriod"
        :step="1"
        :min="0"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
    <el-form-item label="总期数：" prop="totalPeriod">
      <el-input-number
        v-model="periodBatchModel.totalPeriod"
        :step="1"
        :min="0"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
    <el-form-item label="开始期的日期：" prop="startPeriodDate">
      <el-date-picker
        v-model="periodBatchModel.startPeriodDate"
        type="date"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        style="width: 100%;">
      </el-date-picker>
    </el-form-item>
  </el-form>
</template>

<script>
import GetTender from '@/mixins/GetTender'

export default {
  name: 'PeriodBatchForm',
  mixins: [
    GetTender
  ],
  props: {
    periodBatchModel: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  watch: {
    companyGuid (newVal) {
      this.projectGuid = ''
      this.periodBatchModel.tenderGuid = ''
      this.projects = []
      this.tenders = []
      newVal && this._getProjects(newVal)
    },
    projectGuid (newVal) {
      this.periodBatchModel.tenderGuid = ''
      this.tenders = []
      newVal && this._getTenders(newVal)
    }
  },
  data () {
    return {
      companyGuid: '',
      projectGuid: '',
      rules: {
        tenderGuid: [
          {
            required: true,
            message: '请选择标段',
            trigger: 'blur'
          }
        ],
        startPeriodDate: [
          {
            required: true,
            message: '请选择开始期的日期',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    validate () {
      return this.$refs.elForm.validate
    }
  },
  created () {
  }
}
</script>
